$border:		#e6e6e6;
$gris:			#888888;

@mixin no-mobile{
	@include media-breakpoint-only(xs) {
		display:none;
	}
	@include media-breakpoint-only(sm) {
		display:none; 
	}
}

@mixin solo-mobile{
	@include media-breakpoint-only(md) {
		display:none;
	}
	@include media-breakpoint-only(lg) {
		display:none;
	}
	@include media-breakpoint-only(xl) {
		display:none;
	}
}



@keyframes bombita {
  0%  { transform: scale(1); opacity:0.9;}
  50% { transform: scale(0.7); opacity:0.8; }
  100%{ transform: scale(1); opacity:0.9;}
}


//12px
@mixin titulo-interno1{
  	line-height:1rem !important;
	font-size:0.75rem !important;
} 

.ti-1{
	@include titulo-interno1;
}

//14px
@mixin titulo-interno14{
  	line-height:1rem !important;
	font-size:0.85rem !important;
} 

.ti-14{
	@include titulo-interno14;
}

//16px
@mixin titulo-interno16{
  font-size:1rem !important;
  line-height:1.5rem !important;
} 

.ti-16{
	@include titulo-interno16;
}

// 18px
@mixin titulo-interno18{
  font-size:1.1rem !important;
  line-height:1.5rem !important;
}

.ti-18{
	@include titulo-interno18;
}

// 20px
@mixin titulo-interno20{
  font-size:1.25rem !important;
  line-height:1.75rem !important;
}

.ti-20{
	@include titulo-interno20;
}

// 22px
@mixin titulo-interno22{
  font-size:1.37rem !important;
  line-height:1.87rem !important;
}

.ti-22{
	@include titulo-interno22;
}

//titulo de modulo
//25px
@mixin titulo-interno25{
  font-size: 1.56rem !important;
  line-height:2.06rem !important;
 
}

.ti-25{
	@include titulo-interno25;
}

// 27px
@mixin titulo-interno27{
  	line-height:2.21rem !important;
	font-size:1.71rem !important;
} 

.ti-27{
	@include titulo-interno27;
}

// 30px
@mixin titulo-interno30{
  	line-height:2.37rem !important;
	font-size:1.87rem !important;
} 

.ti-30{
	@include titulo-interno30;
}

// 37px 
@mixin titulo-interno37{
  	line-height:2.6rem !important;
	font-size:2.3rem !important;
} 

.ti-37{
	@include titulo-interno37;
}

//40px
@mixin titulo-interno40{
  	line-height:2.8rem !important;
	font-size:2.5rem !important;
	
} 

.ti-40{
	@include titulo-interno40;
}

// 48px
@mixin titulo-interno48{
  	line-height:3.5rem !important;
	font-size:3rem !important;
	
}

.ti-48{
	@include titulo-interno48;
}

// 50px
@mixin titulo-interno50{
  	line-height:3.62rem !important;
	font-size:3.125rem !important;
}

.ti-50{
	@include titulo-interno50;
}

// 58px
@mixin titulo-interno58{
  	line-height:4.1rem !important;
	font-size:3.62rem !important;
}

.ti-58{
	@include titulo-interno58;
}

// 60px
@mixin titulo-interno60{
  	line-height:4.25rem !important;
	font-size:3.75rem !important;
	
}

.ti-60{
	@include titulo-interno60;
}



.color-primary{
	color:$primary;
}
.color-secondary{
	color:$secondary;
}

.fw-thin{
	font-weight:$font-weight-lighter !important;
}
@mixin fw-thin{
	font-weight:$font-weight-lighter !important;
}

.fw-light{
	font-weight:$font-weight-light !important;
}
@mixin fw-light{
	font-weight:$font-weight-light !important;
}

.fw-normal{
	font-weight:$font-weight-normal !important;
}
@mixin fw-normal{
	font-weight:$font-weight-normal !important;
}

.fw-bold{
	font-weight:$font-weight-bold !important;
}
@mixin fw-bold{
	font-weight:$font-weight-bold !important;
}

.fw-bolder{
	font-weight:$font-weight-bolder !important;
}
@mixin fw-bolder{
	font-weight:$font-weight-bolder !important;
}


.bg-light-gray{
	background-color:#f2f2f2;
}
@mixin bg-light-gray{
	background-color:#f2f2f2;
}

.bg-textura{
	background-image:url(../img/textura1.png);  
}
@mixin bg-light-gray{
	background-image:url(../img/textura1.png);
}